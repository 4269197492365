@font-face {
    font-family: codigra;
    src: url(Codigra.woff);
}

.container {
    font-family: codigra;
    background-color: #f9f4e1;
    margin: -1px;
    user-select: none;
}


.mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    font-family: codigra;
    background-color: #f9f4e1;
    margin: -1px;
    user-select: none;
}

.inviteContainer {
    display: block;
    position: relative;
    object-fit: contain;

    font-size: min(12vw, 96px);
    color: white;
}

.topTitleContainer {
    color: #111B41;
    font-size: min(14vw, 120px);
    position: absolute;
    max-width: 768;
    width: 70%;
    aspect-ratio: 1.5;
}

.title2Container {
    color: #111B41;
    font-size: min(14vw, 120px);
    position: absolute;
    max-width: 768;
    width: 70%;
}

.title3Container {
    color: #111B41;
    font-size: min(14vw, 120px);
    position: absolute;
    max-width: 768;
    width: 70%;
    aspect-ratio: 1.5;
}

.canvasContainer {
    max-width: 768;
    width: 70%;
    object-fit: contain;
    aspect-ratio: 1.5;
    margin-top: -10%;
}

.emptyLine {
    height: 120px;
}

.textEnd2End {
    display: flex;
    justify-content: space-between;
}

.textAtEnd {
    display: flex;
    justify-content: flex-end;
}

.innerTitleContainer
{
    position: absolute;
    width: 100%;
}

.innerTitleTextContainer
{
    margin: 8px;
}

.innerEmptyLine
{
    height: 96px;
    position: absolute;
    width: 100%;
}

.innerText {
    position: absolute;
}

.saveRow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.saveButton {
    border: 0;
    background: none;
    font-family: codigra;
    color: #111B41;
    font-size: min(9vw, 50px);
    position: relative;
    z-index: 1;
    height: 70%;
    margin-left: -9%;
    margin-top: 7.5%;
    display: inline-block;
}

.saveButton:hover {
    transform: scale(1.1);
}

canvas {
    width: 100%;
    height: 100%;
}

.title1 {
    position: relative;
    z-index: 1;
    max-width: 80%;
    left: 33%;
    margin-top: -5%;
}

.title2 {
    position: relative;
    z-index: 1;
    max-width: 80%;
}

.title3 {
    height: 100%;
}