.photo {
    display: inline-flex;
}

.homeButton {

}

button.homeButton {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
}

.rotatingTextRegistryCard {
    transform-origin: 85px 115px;
    animation: rotateText 10s infinite;
    animation-timing-function: linear;
}

.rotatingTextRegistryCard:hover {
    transform: rotate(360deg);
}

@keyframes rotateText {
    0% {
        transform: rotate(360deg);
    }

    /* 50% {
        transform: rotate(180deg);
    } */

    100% {
        transform: rotate(0deg);
    }
}