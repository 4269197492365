.postComingSoon {
    color: #D60D0D;
}

.postList {
    margin: 0px;
    color: #006FC3;
}

.joinBlue {
    color: #006FC3;
}